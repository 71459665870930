<template>
	<div class="Detail w">
		<!-- 面包屑 start -->
		<div class="bread flex align-center font-33 ptb24">
			您现在的位置：<el-breadcrumb
				separator="/"
				separator-class="el-icon-arrow-right"
			>
				<!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item v-if="fromName == 'Lists'" :to="{ path: '/lists' }"
					>政策列表</el-breadcrumb-item
				> -->
				<el-breadcrumb-item :to="{ name: fromObj.name }">{{
					fromObj.title
				}}</el-breadcrumb-item>
				<el-breadcrumb-item>政策详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 面包屑 end -->
		<div class="container">
			<!-- 标题 start -->
			<div class="tit-wrap mt20 bgfff br8 p32 pb24">
				<div class="tit fs20 fw-7 font-33">{{ title }}</div>

				<div class="status-1">
					<div class="tit-bot flex justify-between align-center mt24">
						<div class="left" v-if="type == 1">
							<div class="s-0" v-if="speed_status == 0">未开始</div>
							<div class="s-1" v-if="speed_status == 1">正在执行</div>
							<div class="s-2" v-if="speed_status == 2">已结束</div>
						</div>
						<div class="left" v-else>
							<div style="visibility: hidden">1</div>
						</div>
						<div v-if="status == 1" class="right flex align-center font-33">
							<div class="flex align-center">
								<img src="@/assets/list/eye.png" />
								{{ views }}
							</div>
							<div class="flex align-center">
								<img src="@/assets/imgs/detail-download-icon.png" />
								{{ downloads }}
							</div>
						</div>
					</div>
					<div class="flex">
						<div v-if="false">
							<!-- <a
								v-if="external_links"
								target="_blank"
								:href="isHttp(external_links)"
								class="look mt24 flex align-center"
							>
								查看原文<i class="el-icon-arrow-right"></i
							></a> -->
						</div>
					</div>
				</div>
			</div>
			<!-- 标题 end -->

			<!-- 信息 start -->
			<div class="info-wrap bgfff br8 mt16 p32">
				<div class="fs18 fw-7 font-33 pr00">
					<div class="tit-line flex">
						<span class="pr">政策信息</span>
						<span class="line">政策信息</span>
					</div>
				</div>

				<!-- 概要信息 start -->
				<div class="border-bottom pb24">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						概要信息
					</div>

					<div class="mt16 flex" style="align-items: flex-start">
						<span class="font-66">发布时间：</span>
						<span class="font-33" style="flex: 1">{{ published_date }}</span>
					</div>

					<div class="mt16 flex" style="align-items: flex-start">
						<span class="font-66">发布单位：</span>
						<span class="font-33" style="flex: 1">{{ company }}</span>
					</div>

					<div
						v-if="type == 1"
						class="mt16 flex"
						style="align-items: flex-start"
					>
						<span class="font-66">起止时间：</span>
						<span class="font-33" style="flex: 1"
							>{{ start_date }}至{{ end_date }}</span
						>
					</div>
				</div>
				<!-- 概要信息 end -->

				<!-- 详情 start -->
				<div class="border-bottom pb32">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策详情
					</div>

					<div
						v-html="body"
						class="mt24 font-66"
						style="padding-right: 120px"
					></div>
				</div>
				<!-- 详情 end -->

				<!-- 政策解读 start -->
				<div class="border-bottom pb32">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策享受流程
					</div>
					<div
						v-html="policy_decode"
						class="mt24 font-66"
						style="padding-right: 120px"
					></div>
				</div>
				<!-- 政策解读 end -->

				<!-- 附件 start -->
				<div v-if="enclosure.length > 0" class="">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策申请表格
					</div>

					<div class="font-33 mt24">政策申请表格下载：</div>

					<div
						@click="downFileFun(0, item.id)"
						v-for="(item, index) in enclosure"
						:key="index"
						class="flex mt24"
					>
						<button class="download br16 flex align-center justify-between">
							政策申请表格下载{{ index + 1 }}
							<img class="ml32" src="@/assets/imgs/detail-download.png" />
						</button>
					</div>
				</div>
				<!-- 附件 end -->
			</div>
			<!-- 信息 end -->
		</div>

		<!-- 悬浮 start -->
		<div class="suspended">
			<div @click="collectFun" class="col">
				<img
					:src="
						is_collection
							? require('@/assets/imgs/detail-collect-active.png')
							: require('@/assets/imgs/detail-collect.png')
					"
				/>
				{{ is_collection ? "已" : "" }}收藏
			</div>
			<div @click="serviceFun" class="msg">
				<img src="@/assets/imgs/detail-msg.png" />
				咨询
			</div>
			<div @click="downFileFun(1, '')" class="down">
				<img src="@/assets/imgs/detail-download2.png" />
				下载
			</div>
			<div @click="$pageScrollTop()" class="top">
				<img src="@/assets/imgs/detail-top.png" />
				返回顶部
			</div>
		</div>
		<!-- 悬浮 end -->
	</div>
</template>
<script>
export default {
	name: "Detail",
	data() {
		return {
			// 来自哪里
			fromObj: {
				name: "Index",
				title: "首页",
			},
			fromName: "", // 从那里来的
			id: 0, // 政策 id

			// type: 1, // 发布类型 0 无起止时间; 1 有起止时间;
			// speed_status: 1, // 0 未开始; 1 正在执行; 2 已结束;   依据 开始时间结束时间
			// status: 0, // 0 待审核(审核中); 1 审核通过(审核成功); 2 审核拒绝(审核失败);

			// id	Integer[整数]	政策id
			// title	String[字符串]	标题
			// downloads	Integer[整数]	下载数
			// views	Integer[整数]	浏览量
			// is_enclosure	Integer[整数]	是否存在附件 0否 1是
			// status	Integer[整数]	政策状态 0待审核 1审核成功 2审核失败
			// external_links	String[字符串]	原文链接
			// published_date	String[字符串]	发布日期
			// company	String[字符串]	发布单位
			// start_date	String[字符串]	开始日期
			// end_date	String[字符串]	结束日期
			// body	String[字符串]	政策详情
			// policy_decode	String[字符串]	政策解读
			// enclosure	Array[数组]	附件id
			// speed_status_text	String[字符串]	政策进度文本
			// type	Integer[整数]	是否有起止时间 0否 1是
			// is_collection	Integer[整数]	是否收藏 0否 1是
			// check_reason 拒绝原因
			// check_time 拒接时间
			// address 地址

			body: "",
			company: "",
			create_time: "",
			downloads: 0,
			enclosure: [],
			end_date: "",
			external_links: "",
			is_collection: 0, // 是否收藏
			is_enclosure: "",
			policy_decode: "",
			published_date: "",
			speed_status: 0,
			speed_status_text: "",
			start_date: "",
			status: 0,
			title: "",
			type: "",
			views: 0,
			check_reason: "",
			check_time: "",
			address: "",

			one_id: "", // 分类 id
			two_id: "", // 分类 id
			three_id: "", // 分类 id
			four_id: "", // 分类 id

			collectLoading: false, // 收藏的标记
			delLoading: false, // 删除政策 标记
		};
	},
	created() {
		this.$pageScrollTop();
		let { id = 0 } = this.$route.query;

		this.id = id;
		this.getData();
	},
	methods: {
		// 触发聊天界面 显示聊天
		serviceFun() {
			this.$bus.$emit("CustomerServiceChange", {
				curType: "big",
			});
		},
		isHttp(str) {
			if (str.indexOf("http") == 0 || str.indexOf("https") == 0) {
				return str;
			} else {
				return "http://" + str;
			}
		},
		// 收藏 取消收藏
		collectFun() {
			if (this.collectLoading) {
				return false;
			}

			this.collectLoading = true;

			this.$API
				.detailCollect({
					collect_id: this.id,
					type: 1,
				})
				.then((res) => {
					this.$message.info(res.msg);
					this.collectLoading = false;

					if (res.code == 1) {
						this.is_collection = !this.is_collection;
					}
				});
		},
		// 下载文件
		downFile(sUrl = "") {
			// try {
			//   const elemIF = document.createElement(`iframe`);
			//   elemIF.src = url;
			//   elemIF.style.display = `none`;
			//   document.body.appendChild(elemIF);
			// } catch (e) {
			//   console.log(e);
			// }

			//iOS devices do not support downloading. We have to inform user about this.

			let isChrome =
				window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
			let isSafari =
				window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;

			if (/(iP)/g.test(window.navigator.userAgent)) {
				alert(
					"Your device does not support files downloading. Please try again in desktop browser."
				);
				return false;
			}

			//If in Chrome or Safari - download via virtual link click
			if (isChrome || isSafari) {
				//Creating new link node.
				var link = document.createElement("a");
				link.href = sUrl;

				if (link.download !== undefined) {
					//Set HTML5 download attribute. This will prevent file from opening if supported.
					var fileName = sUrl.substring(sUrl.lastIndexOf("/") + 1, sUrl.length);
					link.download = fileName;
				}

				//Dispatching click event.
				if (document.createEvent) {
					var e = document.createEvent("MouseEvents");
					e.initEvent("click", true, true);
					link.dispatchEvent(e);
					return true;
				}
			}

			// Force file download (whether supported by server).
			if (sUrl.indexOf("?") === -1) {
				sUrl += "?download";
			}

			window.open(sUrl, "_self");
			return false;
		},
		// 附件下载
		downFileFun(type, file_id = "") {
			// if (this.enclosure.length == 0) {
			// 	this.$message.warning("当前政策没有附件");
			// 	return false;
			// }
			this.$API
				.downFile({
					type, // 下载方式 0单文件 1全部下载
					policy_id: this.id, // 政策id
					file_id, // 文件id 全部下载时，传空即可
				})
				.then((res) => {
					if (res.code == 1) {
						this.downFile(res.data);
					} else {
						this.$message.info(res.msg);
					}
				});
		},
		// 获取政策详情
		getData() {
			this.$API
				.detailAjax({
					id: this.id,
				})
				.then((res) => {
					// console.log("ress", res);

					if (res.code == 1) {
						Object.keys(res.data).forEach((item) => {
							switch (item) {
								case "is_collection":
									this[item] = res.data[item] == 1;
									break;
								default:
									this[item] = res.data[item];
									break;
							}
						});
					} else {
						this.$message.info(res.msg);
						setTimeout(() => {
							this.$router.back();
						}, 800);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},

	beforeRouteEnter(to, from, next) {
		let obj = {
			name: "Index",
			title: "首页",
		};

		if (from.name && from.name.toString() != "null") {
			obj = {
				name: from.name,
				title: from.meta.title,
			};
		}

		console.log("from", from);
		next((vm) => {
			vm.fromObj = obj;
			vm.fromName = from.name;
		});
	},
};
</script>
<style scoped lang="scss">
.Detail {
	@extend .h100;
	padding-bottom: 64px;

	.border-bottom {
		border-bottom: 1px solid #eeeeee;
	}

	.container {
		.tit-wrap {
			.tit-bot {
				.left {
					div {
						color: #ffffff;
						border-radius: 16px 0 16px 0;
						padding: 4px 12px;
						font-size: 12px;
						color: #ffffff;

						&.s-0 {
							background-color: #ff8300;
						}

						&.s-1 {
							background-color: #2c8fff;
						}

						&.s-2 {
							background-color: #bfbfbf;
						}
					}
				}
			}

			.look {
				color: #4177dd;

				i {
					font-size: 14px;
					margin-left: 8px;
				}
			}

			.status-1 {
				.right {
					div {
						&:first-child {
							margin-right: 32px;
						}
					}

					img {
						width: 18px;
						height: 18px;
						margin-right: 8px;
					}
				}
			}
		}

		.info-wrap {
			.tit {
				img {
					margin-right: 6px;
					width: 18px;
					height: 12px;
				}
			}

			.download {
				margin: 0;
				padding: 0;
				padding: 12px 24px;
				background-color: #f2f8ff;
				color: #2c8fff;
				min-width: 400px;
				line-height: initial;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	// 悬浮
	.suspended {
		position: fixed;
		bottom: 250px;
		left: 50%;
		width: 80px;
		transform: translateX(520px);

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			background: #fff;
			border: 1px solid #eeeeee;
			color: #333333;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				height: 24px;
				margin-bottom: 8px;
			}

			&:nth-child(2) {
				margin-top: -1px;
			}

			&:nth-child(3) {
				margin-top: -1px;
			}

			&.top {
				margin-top: 16px;

				img {
					width: 20px;
				}
			}
		}
	}
}
</style>
